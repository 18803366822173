@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
.micro_img {
    padding-top: 2%;
    padding-bottom: 2%;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
    -webkit-filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
            filter: drop-shadow(3px 1px 3px rgba(0, 0, 0, 0.14));
    transition: opacity 0.5s ease, transform 0.2s ease;
}

.micro_img.pre_micro {
    animation: 1.2s ease-in 1.2s infinite alternate zoom_in_and_out;
}

.micro_img > svg {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.micro_container {
    width: 100%;
    z-index: 1;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.micro_label {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin: 0;
    font-size: 15px;
}

.loading_micro {
    opacity: 0.5;
}

.finished_micro {
    transform: scale(0);
}

@keyframes zoom_in_and_out {
    from { transform: scale(1); }
    to { transform: scale(1.05); }
}

.wave1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    display: block;
    width: 180%;
    height: 180%;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}

.wave2 {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 140%;
    height: 140%;
    background: radial-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.2));
    border-radius: 100%;
    transition: transform 0.2s linear;
}


.wave_container {
    position: absolute;
    width: 60%;
    height: 90%;
    z-index: -1;
    /*transform: ;*/
    transform: scale(0) translate(12%, -92%);
    transform-origin: 100% -50%;
    transition: transform 0.6s ease;
}

.wave_appear {
    z-index: -1;
    transform: scale(1) translate(30%, -105%);
}
.AlloText {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    opacity: 1;
    transform: scale(1);
    transition: transform 0.3s ease, opacity 0.3s ease;
}

.AlloText > textarea {
    border: none;
    outline: none;
    border: 1px solid grey;
    border-radius: 15px;
    padding: 8px;
    padding-bottom: 12px;
    padding-top: 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 17px;
    width: calc(100% - 16px);
    margin:0;
    box-shadow: none;
    opacity: 1;
    transform: scale(1);
    transition: box-shadow 0.3s ease;
}

.AlloText > textarea:focus {
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
}

.AlloText > .button:hover {
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
}


.AlloText > .button {
    background-color: blue;
    color: white;
    cursor: pointer;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    border-radius: 15px;
    box-shadow: none;
    transition: box-shadow 0.3s ease;
}
